import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
} from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import config from "../config";

function ItineraryForm() {
  const { isLoggedIn, currentUser } = useAuth();
  const navigate = useNavigate();

  const [itinerary, setItinerary] = useState({
    title: "",
    description: "",
    daysCount: 0,
    nightsCount: 0,
    backgroundImage: null,
    additionalImages: [],
    days: [],
    destinations: [],
    highlights: [],
  });
  const [loading, setLoading] = useState(false);
  const [newHighlight, setNewHighlight] = useState("");

  const handleChange = (e) => {
    setItinerary({ ...itinerary, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setItinerary({ ...itinerary, backgroundImage: e.target.files[0] });
  };

  const handleAdditionalImagesChange = (e) => {
    setItinerary({
      ...itinerary,
      additionalImages: [...itinerary.additionalImages, ...e.target.files],
    });
  };

  const removeAdditionalImage = (index) => {
    const updatedImages = itinerary.additionalImages.filter(
      (_, i) => i !== index
    );
    setItinerary({ ...itinerary, additionalImages: updatedImages });
  };

  const handleDayChange = (index, field, value) => {
    const updatedDays = [...itinerary.days];
    if (!updatedDays[index]) {
      updatedDays[index] = { dayNumber: index + 1 };
    }
    updatedDays[index] = { ...updatedDays[index], [field]: value };
    setItinerary({ ...itinerary, days: updatedDays });
  };

  const handleDestinationChange = (index, field, value) => {
    const updatedDestinations = [...itinerary.destinations];
    updatedDestinations[index] = {
      ...updatedDestinations[index],
      [field]: value,
    };
    setItinerary({ ...itinerary, destinations: updatedDestinations });
  };

  const addDestination = () => {
    setItinerary({
      ...itinerary,
      destinations: [...itinerary.destinations, { name: "", duration: "" }],
    });
  };

  const removeDestination = (index) => {
    const updatedDestinations = itinerary.destinations.filter(
      (_, i) => i !== index
    );
    setItinerary({ ...itinerary, destinations: updatedDestinations });
  };

  const handleAddHighlight = () => {
    if (newHighlight.trim()) {
      setItinerary({
        ...itinerary,
        highlights: [...itinerary.highlights, newHighlight.trim()],
      });
      setNewHighlight("");
    }
  };

  const handleRemoveHighlight = (index) => {
    const updatedHighlights = itinerary.highlights.filter(
      (_, i) => i !== index
    );
    setItinerary({ ...itinerary, highlights: updatedHighlights });
  };

  const generateSignature = async (timestamp, apiSecret) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(`timestamp=${timestamp}${apiSecret}`);
    const hashBuffer = await crypto.subtle.digest("SHA-1", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  };

  const uploadToCloudinary = async (file) => {
    if (!currentUser || !currentUser.cloudinary) {
      throw new Error("Cloudinary credentials not available");
    }

    const { cloudName, apiKey, apiSecret } = currentUser.cloudinary;

    if (!cloudName || !apiKey || !apiSecret) {
      throw new Error("Incomplete Cloudinary credentials");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", apiKey);

    const timestamp = Math.round(new Date().getTime() / 1000);
    const signature = await generateSignature(timestamp, apiSecret);

    formData.append("timestamp", timestamp);
    formData.append("signature", signature);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      toast.error("Please log in to create an itinerary");
      navigate("/login");
      return;
    }

    if (
      !itinerary.title ||
      !itinerary.description ||
      !itinerary.backgroundImage
    ) {
      toast.error("Please fill in all required fields");
      return;
    }

    setLoading(true);

    try {
      console.log("Current user:", currentUser);

      const backgroundImageUrl = await uploadToCloudinary(
        itinerary.backgroundImage
      );
      const additionalImageUrls = await Promise.all(
        itinerary.additionalImages.map(uploadToCloudinary)
      );

      const itineraryData = {
        title: itinerary.title,
        description: itinerary.description,
        daysCount: Number(itinerary.daysCount),
        nightsCount: Number(itinerary.nightsCount),
        backgroundImage: backgroundImageUrl,
        additionalImages: additionalImageUrls,
        days: itinerary.days,
        destinations: itinerary.destinations,
        highlights: itinerary.highlights,
      };

      console.log("Sending itinerary data:", itineraryData);

      const response = await axios.post(
        `${config.API_URL}/api/itineraries`,
        itineraryData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      console.log("Response:", response.data);
      toast.success("Itinerary created successfully");
      setItinerary({
        title: "",
        description: "",
        daysCount: 0,
        nightsCount: 0,
        backgroundImage: null,
        additionalImages: [],
        days: [],
        destinations: [],
        highlights: [],
      });
    } catch (error) {
      console.error("Error creating itinerary:", error);
      toast.error(`Failed to create itinerary: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!isLoggedIn) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h6">
          Please log in to create an itinerary.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h2" gutterBottom>
          Create New Itinerary
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="title"
                label="Title"
                value={itinerary.title}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="description"
                label="Description"
                multiline
                rows={4}
                value={itinerary.description}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="number"
                name="daysCount"
                label="Number of Days"
                value={itinerary.daysCount}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                type="number"
                name="nightsCount"
                label="Number of Nights"
                value={itinerary.nightsCount}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" fullWidth>
                  Upload Background Image
                </Button>
              </label>
              {itinerary.backgroundImage && (
                <Typography>{itinerary.backgroundImage.name}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="additional-images"
                type="file"
                multiple
                onChange={handleAdditionalImagesChange}
              />
              <label htmlFor="additional-images">
                <Button variant="contained" component="span" fullWidth>
                  Upload Additional Images
                </Button>
              </label>
              <List>
                {itinerary.additionalImages.map((image, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={image.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => removeAdditionalImage(index)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Highlights
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  fullWidth
                  label="Add Highlight"
                  value={newHighlight}
                  onChange={(e) => setNewHighlight(e.target.value)}
                  variant="outlined"
                />
                <Button
                  onClick={handleAddHighlight}
                  startIcon={<AddIcon />}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {itinerary.highlights.map((highlight, index) => (
                  <Chip
                    key={index}
                    label={highlight}
                    onDelete={() => handleRemoveHighlight(index)}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Destinations
              </Typography>
              {itinerary.destinations.map((destination, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Destination Name"
                      value={destination.name}
                      onChange={(e) =>
                        handleDestinationChange(index, "name", e.target.value)
                      }
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      label="Duration"
                      value={destination.duration}
                      onChange={(e) =>
                        handleDestinationChange(
                          index,
                          "duration",
                          e.target.value
                        )
                      }
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button onClick={() => removeDestination(index)}>
                      <RemoveIcon />
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Button onClick={addDestination} startIcon={<AddIcon />}>
                Add Destination
              </Button>
            </Grid>
            {Array.from({ length: parseInt(itinerary.daysCount) }).map(
              (_, index) => (
                <Grid item xs={12} key={index}>
                  <Typography variant="h6">Day {index + 1}</Typography>
                  <TextField
                    fullWidth
                    label="Description"
                    value={itinerary.days[index]?.description || ""}
                    onChange={(e) =>
                      handleDayChange(index, "description", e.target.value)
                    }
                    margin="normal"
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Activities"
                    value={itinerary.days[index]?.activities || ""}
                    onChange={(e) =>
                      handleDayChange(index, "activities", e.target.value)
                    }
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              )
            )}
            <Grid item xs={12}>
              <Box sx={{ position: "relative" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  fullWidth
                  size="large"
                  sx={{ mt: 2 }}
                >
                  Create Itinerary
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default ItineraryForm;
