import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import DestinationCard from "./DestinationCard";
import EditDestinationModal from "./EditDestinationModal";
import DestinationDetail from "./DestinationDetail";
import { useAuth } from "../contexts/AuthContext";
import config from "../config";

function DestinationList() {
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingDestination, setEditingDestination] = useState(null);
  const [viewingDestination, setViewingDestination] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { isLoggedIn, logout, currentUser } = useAuth();

  const fetchDestinations = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${config.API_URL}/api/destinations`, {
        headers: { "x-auth-token": token },
      });
      setDestinations(response.data);
    } catch (error) {
      console.error("Error fetching destinations:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Your session has expired. Please log in again.");
        logout();
      } else {
        toast.error("Failed to fetch destinations");
      }
    } finally {
      setLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    fetchDestinations();
  }, [fetchDestinations]);

  const handleEdit = (destination) => {
    setEditingDestination(destination);
  };

  const handleView = (destination) => {
    setViewingDestination(destination);
  };

  const handleDelete = async (destinationId) => {
    if (window.confirm("Are you sure you want to delete this destination?")) {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        await axios.delete(
          `${config.API_URL}/api/destinations/${destinationId}`,
          {
            headers: { "x-auth-token": token },
          }
        );
        setDestinations(
          destinations.filter(
            (destination) => destination._id !== destinationId
          )
        );
        toast.success("Destination deleted successfully");
      } catch (error) {
        console.error("Error deleting destination:", error);
        toast.error("Failed to delete destination");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async (updatedDestination) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      console.log("Sending updated destination:", updatedDestination); // Log the data being sent

      const response = await axios.put(
        `${config.API_URL}/api/destinations/${updatedDestination._id}`,
        updatedDestination,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      console.log("Server response:", response.data); // Log the server response

      if (response.status === 200) {
        setDestinations((prevDestinations) =>
          prevDestinations.map((destination) =>
            destination._id === updatedDestination._id
              ? response.data
              : destination
          )
        );
        setEditingDestination(null);
        if (
          viewingDestination &&
          viewingDestination._id === updatedDestination._id
        ) {
          setViewingDestination(response.data);
        }
        toast.success("Destination updated successfully");
      } else {
        throw new Error("Failed to update destination");
      }
    } catch (error) {
      console.error("Error updating destination:", error);
      console.error("Error response:", error.response?.data); // Log the error response
      toast.error(`Failed to update destination: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 64px)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h2"
        gutterBottom
        sx={{ fontWeight: "bold", color: "primary.main", mb: 4 }}
      >
        Discover Destinations
      </Typography>
      {isLoggedIn && (
        <FormControlLabel
          control={
            <Switch checked={isEditMode} onChange={handleEditModeToggle} />
          }
          label="Edit Mode"
        />
      )}
      <Grid container spacing={4}>
        {destinations.map((destination) => (
          <Grid item xs={12} sm={6} md={4} key={destination._id}>
            <DestinationCard
              destination={destination}
              isEditMode={isEditMode}
              onView={handleView}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
      <DestinationDetail
        destination={viewingDestination}
        open={!!viewingDestination}
        onClose={() => setViewingDestination(null)}
      />
      <EditDestinationModal
        destination={editingDestination}
        open={!!editingDestination}
        onClose={() => setEditingDestination(null)}
        onSave={handleSave}
        currentUser={currentUser}
      />
    </Container>
  );
}

export default DestinationList;
