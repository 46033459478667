import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Chip,
  Grid,
  Card,
  CardMedia,
  Divider,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

function EditItineraryModal({ itinerary, open, onClose, onSave }) {
  const [editedItinerary, setEditedItinerary] = useState(null);
  const [newHighlight, setNewHighlight] = useState("");
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (itinerary) {
      setEditedItinerary({
        ...itinerary,
        highlights: itinerary.highlights || [],
        additionalImages: itinerary.additionalImages || [],
        days: itinerary.days || [],
        destinations: itinerary.destinations || [],
      });
    }
  }, [itinerary]);

  useEffect(() => {
    if (editedItinerary) {
      const daysCount = parseInt(editedItinerary.daysCount, 10);
      let updatedDays = [...editedItinerary.days];

      // Add new days if daysCount increased
      while (updatedDays.length < daysCount) {
        updatedDays.push({
          dayNumber: updatedDays.length + 1,
          description: "",
          activities: "",
        });
      }

      // Remove extra days if daysCount decreased
      updatedDays = updatedDays.slice(0, daysCount);

      setEditedItinerary((prev) => ({
        ...prev,
        days: updatedDays,
      }));
    }
  }, [editedItinerary?.daysCount]);

  if (!editedItinerary) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedItinerary((prev) => ({
      ...prev,
      [name]:
        name === "daysCount" || name === "nightsCount"
          ? Math.max(0, parseInt(value, 10))
          : value,
    }));
  };

  const handleImageChange = (e, isBackgroundImage = false) => {
    const file = e.target.files[0];
    if (isBackgroundImage) {
      setEditedItinerary({ ...editedItinerary, backgroundImage: file });
    } else {
      setEditedItinerary({
        ...editedItinerary,
        additionalImages: [...editedItinerary.additionalImages, file],
      });
    }
  };
  const removeImage = (index, isBackgroundImage = false) => {
    if (isBackgroundImage) {
      setEditedItinerary({ ...editedItinerary, backgroundImage: null });
    } else {
      const updatedImages = editedItinerary.additionalImages.filter(
        (_, i) => i !== index
      );
      setEditedItinerary({
        ...editedItinerary,
        additionalImages: updatedImages,
      });
    }
  };

  const handleAddHighlight = () => {
    if (newHighlight.trim()) {
      setEditedItinerary({
        ...editedItinerary,
        highlights: [...editedItinerary.highlights, newHighlight.trim()],
      });
      setNewHighlight("");
    }
  };

  const handleRemoveHighlight = (index) => {
    const updatedHighlights = editedItinerary.highlights.filter(
      (_, i) => i !== index
    );
    setEditedItinerary({ ...editedItinerary, highlights: updatedHighlights });
  };

  const handleDayChange = (index, field, value) => {
    const updatedDays = [...editedItinerary.days];
    updatedDays[index] = { ...updatedDays[index], [field]: value };
    setEditedItinerary({ ...editedItinerary, days: updatedDays });
  };

  const handleDestinationChange = (index, field, value) => {
    const updatedDestinations = [...editedItinerary.destinations];
    updatedDestinations[index] = {
      ...updatedDestinations[index],
      [field]: value,
    };
    setEditedItinerary({
      ...editedItinerary,
      destinations: updatedDestinations,
    });
  };

  const addDestination = () => {
    setEditedItinerary({
      ...editedItinerary,
      destinations: [
        ...editedItinerary.destinations,
        { name: "", duration: "" },
      ],
    });
  };

  const removeDestination = (index) => {
    const updatedDestinations = editedItinerary.destinations.filter(
      (_, i) => i !== index
    );
    setEditedItinerary({
      ...editedItinerary,
      destinations: updatedDestinations,
    });
  };

  const handleSubmit = () => {
    const newErrors = {};
    if (!editedItinerary.backgroundImage) {
      newErrors.backgroundImage = "Background image is required";
    }
    if (editedItinerary.additionalImages.length === 0) {
      newErrors.additionalImages = "At least one additional image is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    onSave(editedItinerary);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Itinerary</DialogTitle>
      <DialogContent>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          centered
        >
          <Tab label="Basic Info" />
          <Tab label="Images" />
          <Tab label="Highlights" />
          <Tab label="Destinations" />
          <Tab label="Daily Itinerary" />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="title"
                  label="Title"
                  value={editedItinerary.title}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  value={editedItinerary.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="daysCount"
                  label="Number of Days"
                  type="number"
                  value={editedItinerary.daysCount}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="nightsCount"
                  label="Number of Nights"
                  type="number"
                  value={editedItinerary.nightsCount}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          )}
          {activeTab === 1 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Background Image
              </Typography>
              {editedItinerary.backgroundImage ? (
                <Card
                  sx={{ position: "relative", width: 200, height: 150, mb: 2 }}
                >
                  <CardMedia
                    component="img"
                    height="150"
                    image={
                      editedItinerary.backgroundImage instanceof File
                        ? URL.createObjectURL(editedItinerary.backgroundImage)
                        : editedItinerary.backgroundImage
                    }
                    alt="Background Image"
                  />
                  <IconButton
                    onClick={() => removeImage(0, true)}
                    sx={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      color: "white",
                      bgcolor: "rgba(0,0,0,0.5)",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              ) : (
                <Typography color="error">{errors.backgroundImage}</Typography>
              )}
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="background-image-upload"
                type="file"
                onChange={(e) => handleImageChange(e, true)}
              />
              <label htmlFor="background-image-upload">
                <Button variant="contained" component="span">
                  {editedItinerary.backgroundImage ? "Change" : "Upload"}{" "}
                  Background Image
                </Button>
              </label>

              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                Additional Images
              </Typography>
              <Grid container spacing={2}>
                {editedItinerary.additionalImages.map((image, index) => (
                  <Grid item key={index}>
                    <Card
                      sx={{ position: "relative", width: 150, height: 100 }}
                    >
                      <CardMedia
                        component="img"
                        height="100"
                        image={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt={`Additional Image ${index + 1}`}
                      />
                      <IconButton
                        onClick={() => removeImage(index)}
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          color: "white",
                          bgcolor: "rgba(0,0,0,0.5)",
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {editedItinerary.additionalImages.length === 0 && (
                <Typography color="error">{errors.additionalImages}</Typography>
              )}
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="additional-images-upload"
                type="file"
                multiple
                onChange={handleImageChange}
              />
              <label htmlFor="additional-images-upload">
                <Button variant="contained" component="span" sx={{ mt: 2 }}>
                  Add Additional Images
                </Button>
              </label>
            </Box>
          )}
          {activeTab === 2 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Highlights
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  fullWidth
                  label="Add Highlight"
                  value={newHighlight}
                  onChange={(e) => setNewHighlight(e.target.value)}
                  variant="outlined"
                />
                <Button
                  onClick={handleAddHighlight}
                  startIcon={<AddIcon />}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {editedItinerary.highlights.map((highlight, index) => (
                  <Chip
                    key={index}
                    label={highlight}
                    onDelete={() => handleRemoveHighlight(index)}
                  />
                ))}
              </Box>
            </Box>
          )}
          {activeTab === 3 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Destinations
              </Typography>
              {editedItinerary.destinations.map((destination, index) => (
                <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label="Destination Name"
                        value={destination.name}
                        onChange={(e) =>
                          handleDestinationChange(index, "name", e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label="Duration"
                        value={destination.duration}
                        onChange={(e) =>
                          handleDestinationChange(
                            index,
                            "duration",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => removeDestination(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
              <Button
                onClick={addDestination}
                startIcon={<AddIcon />}
                variant="contained"
              >
                Add Destination
              </Button>
            </Box>
          )}
          {activeTab === 4 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Daily Itinerary
              </Typography>
              {editedItinerary.days.map((day, index) => (
                <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Day {index + 1}
                  </Typography>
                  <TextField
                    fullWidth
                    label="Description"
                    value={day.description}
                    onChange={(e) =>
                      handleDayChange(index, "description", e.target.value)
                    }
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Activities"
                    value={day.activities}
                    onChange={(e) =>
                      handleDayChange(index, "activities", e.target.value)
                    }
                    margin="normal"
                    multiline
                    rows={3}
                  />
                </Paper>
              ))}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditItineraryModal;
