import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Home() {
  return (
    <Box
      sx={{
        backgroundImage: "url(https://source.unsplash.com/1600x900/?travel)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "calc(100vh - 64px)",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            backgroundColor: "rgba(255,255,255,0.8)",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h1" gutterBottom>
            Plan Your Dream Vacation
          </Typography>
          <Typography variant="h5" paragraph>
            Discover and create amazing travel itineraries for your next
            adventure.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={RouterLink}
            to="/itineraries"
            sx={{ mt: 2 }}
          >
            Get Started
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
