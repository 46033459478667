import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

function DestinationCard({
  destination,
  isEditMode,
  onView,
  onEdit,
  onDelete,
}) {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: 3,
        "&:hover": { boxShadow: 6 },
        borderRadius: 2,
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={destination.image}
        alt={destination.name}
        sx={{ cursor: "pointer" }}
        onClick={() => onView(destination)}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {destination.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {destination.location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {destination.description.substring(0, 100)}...
        </Typography>
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => onView(destination)}
          variant="contained"
          color="primary"
        >
          View Details
        </Button>
        {isEditMode && (
          <Box>
            <IconButton
              onClick={() => onEdit(destination)}
              color="secondary"
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => onDelete(destination._id)}
              color="error"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default DestinationCard;
