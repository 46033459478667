import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Chip,
} from "@mui/material";

function ItineraryDetail({ itinerary, open, onClose }) {
  if (!itinerary) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{itinerary.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardMedia
                component="img"
                height="300"
                image={itinerary.backgroundImage}
                alt={itinerary.title}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1">{itinerary.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Trip Details
            </Typography>
            <Typography variant="body1">
              Duration: {itinerary.daysCount} days, {itinerary.nightsCount}{" "}
              nights
            </Typography>
          </Grid>
          {itinerary.highlights && itinerary.highlights.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Highlights
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {itinerary.highlights.map((highlight, index) => (
                  <Chip key={index} label={highlight} />
                ))}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Destinations
            </Typography>
            <Stepper orientation="vertical">
              {itinerary.destinations.map((destination, index) => (
                <Step key={index} active={true}>
                  <StepLabel>
                    <Typography variant="subtitle1">
                      {destination.name}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography variant="body2">
                      Duration: {destination.duration}
                    </Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Daily Itinerary
            </Typography>
            {itinerary.days.map((day, index) => (
              <Card key={index} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">Day {day.dayNumber}</Typography>
                  <Typography variant="body1">{day.description}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Activities: {day.activities}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Grid>
          {itinerary.additionalImages &&
            itinerary.additionalImages.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Additional Images
                </Typography>
                <Grid container spacing={2}>
                  {itinerary.additionalImages.map((image, index) => (
                    <Grid item xs={4} key={index}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="150"
                          image={image}
                          alt={`Additional image ${index + 1}`}
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <Box sx={{ m: 2 }}>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </Box>
    </Dialog>
  );
}

export default ItineraryDetail;
