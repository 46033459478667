import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import ItineraryCard from "./ItineraryCard";
import ItineraryDetail from "./ItineraryDetail"; // Make sure to import this
import config from "../config";

function DestinationDetail({ destination, open, onClose }) {
  const [relatedItineraries, setRelatedItineraries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewingItinerary, setViewingItinerary] = useState(null);

  useEffect(() => {
    if (destination && open) {
      fetchRelatedItineraries();
    }
  }, [destination, open]);

  const fetchRelatedItineraries = async () => {
    if (!destination) return;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${config.API_URL}/api/itineraries`, {
        params: { destinationName: destination.name },
        headers: { "x-auth-token": localStorage.getItem("token") },
      });
      console.log("Related itineraries response:", response.data);
      setRelatedItineraries(response.data);
    } catch (error) {
      console.error("Error fetching related itineraries:", error);
      setError("Failed to fetch related itineraries. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleViewItinerary = (itinerary) => {
    setViewingItinerary(itinerary);
  };

  if (!destination) return null;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{destination.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardMedia
                  component="img"
                  height="300"
                  image={destination.image}
                  alt={destination.name}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <Typography variant="body1">{destination.description}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Location
              </Typography>
              <Typography variant="body1">{destination.location}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Best Time to Visit
              </Typography>
              <Typography variant="body1">
                {destination.bestTimeToVisit}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Climate
              </Typography>
              <Typography variant="body1">{destination.climate}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Attractions
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {destination.attractions.map((attraction, index) => (
                  <Chip key={index} label={attraction} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Wildlife
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {destination.wildlife.map((animal, index) => (
                  <Chip key={index} label={animal} />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Related Itineraries
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <Typography color="error">{error}</Typography>
              ) : relatedItineraries.length > 0 ? (
                <Grid container spacing={2}>
                  {relatedItineraries.map((itinerary) => (
                    <Grid item xs={12} sm={6} md={4} key={itinerary._id}>
                      <ItineraryCard
                        itinerary={itinerary}
                        onView={() => handleViewItinerary(itinerary)}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography>No related itineraries found.</Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <Box sx={{ m: 2 }}>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </Box>
      </Dialog>

      {viewingItinerary && (
        <ItineraryDetail
          itinerary={viewingItinerary}
          open={!!viewingItinerary}
          onClose={() => setViewingItinerary(null)}
        />
      )}
    </>
  );
}

export default DestinationDetail;
