import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  IconButton,
  Chip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

function ItineraryCard({ itinerary, isEditMode, onView, onEdit, onDelete }) {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: 3,
        "&:hover": { boxShadow: 6 },
        borderRadius: 2,
      }}
    >
      <CardMedia
        component="img"
        height="200"
        image={itinerary.backgroundImage}
        alt={itinerary.title}
        sx={{ cursor: "pointer" }}
        onClick={() => onView(itinerary)}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ fontWeight: "bold" }}
        >
          {itinerary.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {itinerary.daysCount} days, {itinerary.nightsCount} nights
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {itinerary.description.substring(0, 100)}...
        </Typography>
        {itinerary.highlights && itinerary.highlights.length > 0 && (
          <Box sx={{ mt: 2 }}>
            {itinerary.highlights.map((highlight, index) => (
              <Chip
                key={index}
                label={highlight}
                sx={{ mr: 1, mb: 1 }}
                size="small"
              />
            ))}
          </Box>
        )}
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Button
          startIcon={<VisibilityIcon />}
          onClick={() => onView(itinerary)}
          variant="contained"
          color="primary"
        >
          View Details
        </Button>
        {isEditMode && (
          <Box>
            <IconButton
              onClick={() => onEdit(itinerary)}
              color="secondary"
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => onDelete(itinerary._id)}
              color="error"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default ItineraryCard;
