import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ResponsiveLayout from "./components/ResponsiveLayout";
import Home from "./components/Home";
import DestinationList from "./components/DestinationList";
import DestinationForm from "./components/DestinationForm";
import ItineraryList from "./components/ItineraryList";
import ItineraryForm from "./components/ItineraryForm";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      light: "#42a5f5",
      dark: "#1565c0",
    },
    secondary: {
      main: "#ff9800",
      light: "#ffb74d",
      dark: "#f57c00",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        },
      },
    },
  },
});

function ProtectedRoute({ children }) {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/login" />;
}

function App() {
  const { isLoggedIn } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ResponsiveLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/destinations"
              element={
                isLoggedIn ? <DestinationList /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/create-destination"
              element={
                <ProtectedRoute>
                  <DestinationForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/itineraries"
              element={
                isLoggedIn ? <ItineraryList /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/create-itinerary"
              element={
                <ProtectedRoute>
                  <ItineraryForm />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </ResponsiveLayout>
      </Router>
      <ToastContainer position="bottom-right" />
    </ThemeProvider>
  );
}

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
