import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import config from "../config";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkAuthStatus = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const response = await axios.get(`${config.API_URL}/api/auth/me`, {
          headers: { "x-auth-token": token },
        });
        setCurrentUser(response.data); // This includes Cloudinary credentials
        setIsLoggedIn(true);
      } catch (error) {
        console.error("Auth check failed:", error);
        localStorage.removeItem("token");
        setCurrentUser(null);
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${config.API_URL}/api/auth/login`, {
        username,
        password,
      });
      localStorage.setItem("token", response.data.token);
      setCurrentUser(response.data.user); // This includes Cloudinary credentials
      setIsLoggedIn(true);
      return response.data.user;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    setCurrentUser(null);
    setIsLoggedIn(false);
  }, []);

  const value = {
    currentUser,
    isLoggedIn,
    login,
    logout,
    checkAuthStatus,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
