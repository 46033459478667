import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Box,
  Chip,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import config from "../config";

function DestinationForm() {
  const { isLoggedIn, currentUser } = useAuth();
  const navigate = useNavigate();

  const [destination, setDestination] = useState({
    name: "",
    description: "",
    location: "",
    bestTimeToVisit: "",
    climate: "",
    image: null,
    attractions: [],
    wildlife: [],
  });
  const [loading, setLoading] = useState(false);
  const [newAttraction, setNewAttraction] = useState("");
  const [newWildlife, setNewWildlife] = useState("");

  const handleChange = (e) => {
    setDestination({ ...destination, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setDestination({ ...destination, image: e.target.files[0] });
  };

  const handleAddAttraction = () => {
    if (newAttraction.trim()) {
      setDestination({
        ...destination,
        attractions: [...destination.attractions, newAttraction.trim()],
      });
      setNewAttraction("");
    }
  };

  const handleAddWildlife = () => {
    if (newWildlife.trim()) {
      setDestination({
        ...destination,
        wildlife: [...destination.wildlife, newWildlife.trim()],
      });
      setNewWildlife("");
    }
  };

  const handleRemoveAttraction = (index) => {
    const updatedAttractions = destination.attractions.filter(
      (_, i) => i !== index
    );
    setDestination({ ...destination, attractions: updatedAttractions });
  };

  const handleRemoveWildlife = (index) => {
    const updatedWildlife = destination.wildlife.filter((_, i) => i !== index);
    setDestination({ ...destination, wildlife: updatedWildlife });
  };

  const generateSignature = async (timestamp, apiSecret) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(`timestamp=${timestamp}${apiSecret}`);
    const hashBuffer = await crypto.subtle.digest("SHA-1", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  };

  const uploadToCloudinary = async (file) => {
    if (!currentUser || !currentUser.cloudinary) {
      throw new Error("Cloudinary credentials not available");
    }

    const { cloudName, apiKey, apiSecret } = currentUser.cloudinary;

    if (!cloudName || !apiKey || !apiSecret) {
      throw new Error("Incomplete Cloudinary credentials");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", apiKey);

    const timestamp = Math.round(new Date().getTime() / 1000);
    const signature = await generateSignature(timestamp, apiSecret);

    formData.append("timestamp", timestamp);
    formData.append("signature", signature);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error.message);
      }
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      toast.error("Please log in to create a destination");
      navigate("/login");
      return;
    }

    if (
      !destination.name ||
      !destination.description ||
      !destination.location ||
      !destination.image
    ) {
      toast.error("Please fill in all required fields");
      return;
    }

    setLoading(true);

    try {
      let imageUrl;
      try {
        imageUrl = await uploadToCloudinary(destination.image);
      } catch (error) {
        throw new Error(`Failed to upload image: ${error.message}`);
      }

      const destinationData = {
        ...destination,
        image: imageUrl,
      };

      const response = await axios.post(
        `${config.API_URL}/api/destinations`,
        destinationData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      console.log("Response:", response.data);
      toast.success("Destination created successfully");
      navigate("/destinations");
    } catch (error) {
      console.error("Error creating destination:", error);
      toast.error(`Failed to create destination: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!isLoggedIn) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h6">
          Please log in to create a destination.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Typography variant="h2" gutterBottom>
          Create New Destination
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="name"
                label="Destination Name"
                value={destination.name}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="description"
                label="Description"
                multiline
                rows={4}
                value={destination.description}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                name="location"
                label="Location"
                value={destination.location}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="bestTimeToVisit"
                label="Best Time to Visit"
                value={destination.bestTimeToVisit}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="climate"
                label="Climate"
                value={destination.climate}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" fullWidth>
                  Upload Image
                </Button>
              </label>
              {destination.image && (
                <Typography>{destination.image.name}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Attractions
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  fullWidth
                  label="Add Attraction"
                  value={newAttraction}
                  onChange={(e) => setNewAttraction(e.target.value)}
                  variant="outlined"
                />
                <Button
                  onClick={handleAddAttraction}
                  startIcon={<AddIcon />}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {destination.attractions.map((attraction, index) => (
                  <Chip
                    key={index}
                    label={attraction}
                    onDelete={() => handleRemoveAttraction(index)}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Wildlife
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <TextField
                  fullWidth
                  label="Add Wildlife"
                  value={newWildlife}
                  onChange={(e) => setNewWildlife(e.target.value)}
                  variant="outlined"
                />
                <Button
                  onClick={handleAddWildlife}
                  startIcon={<AddIcon />}
                  sx={{ ml: 1 }}
                >
                  Add
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {destination.wildlife.map((animal, index) => (
                  <Chip
                    key={index}
                    label={animal}
                    onDelete={() => handleRemoveWildlife(index)}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ position: "relative" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  fullWidth
                  size="large"
                  sx={{ mt: 2 }}
                >
                  Create Destination
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default DestinationForm;
