import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  Chip,
  IconButton,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
function EditDestinationModal({
  destination,
  open,
  onClose,
  onSave,
  currentUser,
}) {
  const [editedDestination, setEditedDestination] = useState(null);
  const [newAttraction, setNewAttraction] = useState("");
  const [newWildlife, setNewWildlife] = useState("");

  useEffect(() => {
    if (destination) {
      setEditedDestination({ ...destination });
    }
  }, [destination]);

  if (!editedDestination) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedDestination((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    setEditedDestination((prev) => ({ ...prev, image: e.target.files[0] }));
  };

  const handleAddAttraction = () => {
    if (newAttraction.trim()) {
      setEditedDestination((prev) => ({
        ...prev,
        attractions: [...prev.attractions, newAttraction.trim()],
      }));
      setNewAttraction("");
    }
  };

  const handleAddWildlife = () => {
    if (newWildlife.trim()) {
      setEditedDestination((prev) => ({
        ...prev,
        wildlife: [...prev.wildlife, newWildlife.trim()],
      }));
      setNewWildlife("");
    }
  };

  const handleRemoveAttraction = (index) => {
    setEditedDestination((prev) => ({
      ...prev,
      attractions: prev.attractions.filter((_, i) => i !== index),
    }));
  };

  const handleRemoveWildlife = (index) => {
    setEditedDestination((prev) => ({
      ...prev,
      wildlife: prev.wildlife.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    try {
      let updatedDestination = { ...editedDestination };

      // Handle image upload if a new image is selected
      if (editedDestination.image instanceof File) {
        const imageUrl = await uploadToCloudinary(editedDestination.image);
        updatedDestination.image = imageUrl;
      }

      await onSave(updatedDestination);
      onClose();
    } catch (error) {
      console.error("Error saving destination:", error);
      toast.error(`Failed to save destination: ${error.message}`);
    }
  };

  // Add this function to handle Cloudinary upload
  const uploadToCloudinary = async (file) => {
    if (!currentUser || !currentUser.cloudinary) {
      throw new Error("Cloudinary credentials not available");
    }

    const { cloudName, apiKey, apiSecret } = currentUser.cloudinary;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", apiKey);

    const timestamp = Math.round(new Date().getTime() / 1000);
    const signature = await generateSignature(timestamp, apiSecret);

    formData.append("timestamp", timestamp);
    formData.append("signature", signature);

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to upload image to Cloudinary");
    }

    const data = await response.json();
    return data.secure_url;
  };

  // Add this function to generate Cloudinary signature
  const generateSignature = async (timestamp, apiSecret) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(`timestamp=${timestamp}${apiSecret}`);
    const hashBuffer = await crypto.subtle.digest("SHA-1", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {destination ? "Edit Destination" : "Add New Destination"}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          label="Destination Name"
          value={editedDestination.name}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="description"
          label="Description"
          multiline
          rows={4}
          value={editedDestination.description}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="location"
          label="Location"
          value={editedDestination.location}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="bestTimeToVisit"
          label="Best Time to Visit"
          value={editedDestination.bestTimeToVisit}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="climate"
          label="Climate"
          value={editedDestination.climate}
          onChange={handleChange}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Image</Typography>
          <input accept="image/*" type="file" onChange={handleImageChange} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Attractions</Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <TextField
              fullWidth
              value={newAttraction}
              onChange={(e) => setNewAttraction(e.target.value)}
              placeholder="Add attraction"
            />
            <IconButton onClick={handleAddAttraction}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {editedDestination.attractions.map((attraction, index) => (
              <Chip
                key={index}
                label={attraction}
                onDelete={() => handleRemoveAttraction(index)}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Wildlife</Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <TextField
              fullWidth
              value={newWildlife}
              onChange={(e) => setNewWildlife(e.target.value)}
              placeholder="Add wildlife"
            />
            <IconButton onClick={handleAddWildlife}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {editedDestination.wildlife.map((animal, index) => (
              <Chip
                key={index}
                label={animal}
                onDelete={() => handleRemoveWildlife(index)}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save Destination
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditDestinationModal;
