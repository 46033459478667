import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import ItineraryCard from "./ItineraryCard";
import EditItineraryModal from "./EditItineraryModal";
import ItineraryDetail from "./ItineraryDetail";
import { useAuth } from "../contexts/AuthContext";
import config from "../config";

function ItineraryList() {
  const [itineraries, setItineraries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingItinerary, setEditingItinerary] = useState(null);
  const [viewingItinerary, setViewingItinerary] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { isLoggedIn, logout, currentUser } = useAuth();

  const fetchItineraries = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(`${config.API_URL}/api/itineraries`, {
        headers: { "x-auth-token": token },
      });
      setItineraries(response.data);
    } catch (error) {
      console.error("Error fetching itineraries:", error);
      if (error.response && error.response.status === 401) {
        toast.error("Your session has expired. Please log in again.");
        logout();
      } else {
        toast.error("Failed to fetch itineraries");
      }
    } finally {
      setLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    fetchItineraries();
  }, [fetchItineraries]);

  const handleEdit = (itinerary) => {
    setEditingItinerary(itinerary);
  };

  const handleView = (itinerary) => {
    setViewingItinerary(itinerary);
  };

  const handleDelete = async (itineraryId) => {
    if (window.confirm("Are you sure you want to delete this itinerary?")) {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        await axios.delete(`${config.API_URL}/api/itineraries/${itineraryId}`, {
          headers: { "x-auth-token": token },
        });
        setItineraries(
          itineraries.filter((itinerary) => itinerary._id !== itineraryId)
        );
        toast.success("Itinerary deleted successfully");
      } catch (error) {
        console.error("Error deleting itinerary:", error);
        toast.error("Failed to delete itinerary");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = async (updatedItinerary) => {
    try {
      setLoading(true);
      console.log("Updating itinerary:", updatedItinerary);

      // Process images if they are File objects
      let processedItinerary = { ...updatedItinerary };

      if (updatedItinerary.backgroundImage instanceof File) {
        const uploadedUrl = await uploadImageToCloudinary(
          updatedItinerary.backgroundImage
        );
        processedItinerary.backgroundImage = uploadedUrl;
      }

      if (
        updatedItinerary.additionalImages &&
        updatedItinerary.additionalImages.length > 0
      ) {
        const processedAdditionalImages = await Promise.all(
          updatedItinerary.additionalImages.map(async (image) => {
            if (image instanceof File) {
              return await uploadImageToCloudinary(image);
            }
            return image; // If it's already a string URL, keep it as is
          })
        );
        processedItinerary.additionalImages = processedAdditionalImages;
      }

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${config.API_URL}/api/itineraries/${processedItinerary._id}`,
        processedItinerary,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);

      if (response.status === 200) {
        setItineraries((prevItineraries) =>
          prevItineraries.map((itinerary) =>
            itinerary._id === processedItinerary._id ? response.data : itinerary
          )
        );
        setEditingItinerary(null);
        if (
          viewingItinerary &&
          viewingItinerary._id === processedItinerary._id
        ) {
          setViewingItinerary(response.data);
        }
        toast.success("Itinerary updated successfully");
      } else {
        throw new Error("Failed to update itinerary");
      }
    } catch (error) {
      console.error("Error updating itinerary:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      toast.error(`Failed to update itinerary: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to upload image to Cloudinary
  const uploadImageToCloudinary = async (file) => {
    const { cloudName, apiKey, apiSecret } = currentUser.cloudinary;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", apiKey);

    const timestamp = Math.round(new Date().getTime() / 1000);
    const signature = await generateSignature(timestamp, apiSecret);

    formData.append("timestamp", timestamp);
    formData.append("signature", signature);

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        formData
      );
      return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading image to Cloudinary:", error);
      throw error;
    }
  };

  // Helper function to generate signature for Cloudinary
  const generateSignature = async (timestamp, apiSecret) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(`timestamp=${timestamp}${apiSecret}`);
    const hashBuffer = await crypto.subtle.digest("SHA-1", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  };
  const handleEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 64px)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h2"
        gutterBottom
        sx={{ fontWeight: "bold", color: "primary.main", mb: 4 }}
      >
        Discover Itineraries
      </Typography>
      {isLoggedIn && (
        <FormControlLabel
          control={
            <Switch checked={isEditMode} onChange={handleEditModeToggle} />
          }
          label="Edit Mode"
        />
      )}
      <Grid container spacing={4}>
        {itineraries.map((itinerary) => (
          <Grid item xs={12} sm={6} md={4} key={itinerary._id}>
            <ItineraryCard
              itinerary={itinerary}
              isEditMode={isEditMode}
              onView={handleView}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
      <ItineraryDetail
        itinerary={viewingItinerary}
        open={!!viewingItinerary}
        onClose={() => setViewingItinerary(null)}
      />
      <EditItineraryModal
        itinerary={editingItinerary}
        open={!!editingItinerary}
        onClose={() => setEditingItinerary(null)}
        onSave={handleSave}
        currentUser={currentUser}
      />
    </Container>
  );
}

export default ItineraryList;
